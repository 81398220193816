import { Skeleton } from '@domains/shared/components/Skeleton/Skeleton';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const RecommendedAdsSectionSkeleton = styled(Skeleton)`
    height: 500px;

    @media (min-width: ${theme.mediaQueries.mdMin}) {
        height: 286px;
    }
`;
