import type { SearchSimilarAdsServerResponse } from '@domains/ad/types/SearchSimilarAdsServerResponse';
import { gql } from '@urql/core';

export const SEARCH_RESULTS_FOR_SIMILAR_ADS_QUERY = gql<SearchSimilarAdsServerResponse>`
    query GetSearchResults(
        $lang: Language!
        $page: Page!
        $filterAttributes: FilterAttributes
        $filterLocations: FilterLocations
        $sort: SortOrder
    ) {
        searchAds(
            lang: $lang
            page: $page
            filterAttributes: $filterAttributes
            filterLocations: $filterLocations
            sort: $sort
        ) {
            __typename
            ... on FoundAds {
                __typename
                items {
                    id
                    title
                    slug
                    estate
                    developmentId
                    developmentTitle
                    developmentUrl
                    transaction
                    images {
                        medium
                    }
                    totalPrice {
                        value
                        currency
                    }
                    rentPrice {
                        value
                        currency
                    }
                    priceFromPerSquareMeter {
                        value
                        currency
                    }
                    pricePerSquareMeter {
                        value
                        currency
                    }
                    areaInSquareMeters
                    terrainAreaInSquareMeters
                    investmentUnitsAreaInSquareMeters {
                        from
                        to
                    }
                }
            }
        }
    }
`;
