import { replacePlaceholders } from '@domains/shared/helpers/replacePlaceholders';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FC } from 'react';

import AlertIcon from './assets/alertIcon.svg';
import { AlertText, BoldText, IconWrapper, Wrapper } from './ExpiredAdAlert.theme';

export const ExpiredAdAlert: FC = () => {
    const [t] = useTranslations();

    return (
        <Wrapper data-cy="expired-ad-alert">
            <IconWrapper>
                <AlertIcon />
            </IconWrapper>
            <AlertText>
                {replacePlaceholders(t('frontend.ad.expired-ad.alert.text'), (text) => {
                    return <BoldText>{text}</BoldText>;
                })}
            </AlertText>
        </Wrapper>
    );
};
