import type { SpecialOffer } from '@type/ad/specialOffer';
import { SPECIAL_OFFER_VALUE } from '@type/ad/specialOffer';
/* eslint-disable @typescript-eslint/naming-convention -- SpecialOffer keys returned from BE */

const SPECIAL_OFFER_TRACKING_PARAM = {
    AttractivePaymentSchedule: SPECIAL_OFFER_VALUE.attractivePaymentSchedule,
    PriceDiscount: SPECIAL_OFFER_VALUE.priceDiscount,
    FreeParking: SPECIAL_OFFER_VALUE.freeParking,
    PreSale: SPECIAL_OFFER_VALUE.preSale,
    LastMinute: SPECIAL_OFFER_VALUE.lastMinute,
} as const;
/* eslint-enable @typescript-eslint/naming-convention */

type SpecialOfferTrackingParamValues = ObjectValues<typeof SPECIAL_OFFER_TRACKING_PARAM>;

export const getSpecialOfferTypeTrackingValue = (
    specialOffer?: SpecialOffer | null,
): SpecialOfferTrackingParamValues | null => {
    if (!specialOffer) {
        return null;
    }

    const specialOfferType = specialOffer.__typename as keyof typeof SPECIAL_OFFER_TRACKING_PARAM;

    return SPECIAL_OFFER_TRACKING_PARAM[specialOfferType];
};
