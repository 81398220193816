import { AD_CONFIG } from '@config/ad/adConfig';
import { URL_LOCATION_FILLER } from '@domains/shared/consts/urlLocationFiller';
import { getTranslationFromValue } from '@domains/shared/helpers/getTranslationFromValue';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';
import type { CategoryType } from '@type/ad/attributes/advertCategory';
import { ESTATE } from '@type/search/filters/estate';

import type { PropertyTypeWithStudioFlat } from '../helpers/generateLocationBreadcrumbs';
import { generateLocationBreadcrumbs } from '../helpers/generateLocationBreadcrumbs';
import type { Ad, AdBreadcrumb } from '../types/Ad';

const TRANSLATION_PREFIX = 'frontend.search.item.';

const getFixedEstate = (
    isInvestment: boolean,
    estate: PropertyTypeWithStudioFlat,
    characteristics: AdvertCharacteristic[],
): PropertyTypeWithStudioFlat => {
    const isStudioFlat =
        estate === ESTATE.flat && characteristics.find((char) => char.key === 'rooms_num')?.value === '1';
    const allCategoryEstate = !isStudioFlat ? estate : ESTATE.studioFlat;

    return isInvestment ? ESTATE.investment : allCategoryEstate;
};

export const useLocationBreadcrumbs = (ad: Ad): AdBreadcrumb[] => {
    const [t] = useTranslations();
    const { lang } = useSiteSettings();
    const { name: estate, type: transaction } = ad.adCategory;
    const { shouldUseLocationService, availableMarketsBreadcrumb } = AD_CONFIG;
    //url like investment/investment is invalid, should be sell/investment
    const isInvestment = transaction === ESTATE.investment;
    const fixedTransaction: CategoryType = isInvestment ? 'SELL' : transaction;
    const fixedEstate = getFixedEstate(isInvestment, estate, ad.characteristics);

    const topLabelKey = isInvestment
        ? `${TRANSLATION_PREFIX}development-investment`
        : getTranslationFromValue(TRANSLATION_PREFIX, `${fixedEstate.toLowerCase()}-for-${transaction.toLowerCase()}`);
    const topLabel = t(topLabelKey);

    const locations = ad.location.reverseGeocoding?.locations;
    const location = URL_LOCATION_FILLER.allLocations.label || '';

    const breadcrumbsLocationDecorator = (breadcrumbs: AdBreadcrumb[]): AdBreadcrumb[] => {
        const [firstBreadcrumb, ...rest] = breadcrumbs;

        const { url } = firstBreadcrumb;
        firstBreadcrumb.url = url.includes(location) ? url : `${url}/${location}`;

        return [firstBreadcrumb, ...rest];
    };

    return shouldUseLocationService && locations
        ? generateLocationBreadcrumbs({
              lang,
              transaction: fixedTransaction,
              estate: fixedEstate,
              topLabel,
              locations,
              location,
              currentLabel: ad.title,
              market: ad.market,
              adInformations: ad.topInformation,
              t,
              availableMarketsBreadcrumb,
          })
        : breadcrumbsLocationDecorator(ad.breadcrumbs);
};
