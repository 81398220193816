import type { AdBreadcrumb } from '@domains/ad/types/Ad';

interface MicroData {
    '@type': string;
    position: number;
    item: {
        '@id': string;
        name: string;
    };
}

interface MicroDataItems {
    '@context': string;
    '@type': string;
    itemListElement: MicroData[];
}

const generateMicroData = (items: AdBreadcrumb[]): MicroData[] =>
    items.map((data, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
            '@id': data.url,
            name: data.label,
        },
    }));

export const getMicrodataItems = (items: AdBreadcrumb[]): MicroDataItems => ({
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: generateMicroData(items),
});
