import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

/* max-width: value must be the same as the wider columns in TwoColumnLayout 844px + 2x(padding)16px */
export const ExpiredAdContentLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 876px;
    margin: 0 auto ${theme.space[32]};
    padding: 0 ${theme.space[16]};
`;
