import { getEstateTranslationKeyPrefix } from '@domains/shared/helpers/getEstateTranslationKeyPrefix';
import { getRoomNumberByNumeralValue } from '@domains/shared/helpers/getRoomNumberByTheString';
import { getTransactionTranslationKeyPrefix } from '@domains/shared/helpers/getTransactionTranslationKeyPrefix';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Locale } from '@lib/i18n/types/locale';
import type { AdInformation } from '@type/ad/adDetails';
import type { CategoryType } from '@type/ad/attributes/advertCategory';
import type { PropertyType } from '@type/ad/attributes/advertProperty';
import type { Market } from '@type/ad/attributes/advertSell';
import type { ReverseGeocodingLocation } from '@type/location/reverseGeocoding';
import { ESTATE } from '@type/search/filters/estate';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import type { UseTypes } from '@type/search/filters/useTypes';
import { SEGMENT_TYPE } from '@type/search/segmentTypes';
import { buildSearchUrl } from '@widgets/search/buildSearchUrl';
import { getSegmentByValue } from '@widgets/search/getSegment';

import type { AdBreadcrumb } from '../types/Ad';

interface GenerateLocationBreadcrumbsProps {
    locations: ReverseGeocodingLocation[];
    location?: string;
    lang: Locale;
    currentLabel: string;
    transaction: CategoryType;
    estate: PropertyTypeWithStudioFlat;
    topLabel: string;
    market: Market;
    t: Translator;
    adInformations: AdInformation[];
    availableMarketsBreadcrumb: Market[];
}

interface BasicUrlParams {
    lang: Locale;
    transaction: CategoryType;
    estate: PropertyTypeWithStudioFlat;
    isInternalRoute: boolean;
}

export type PropertyTypeWithStudioFlat = PropertyType | 'STUDIO_FLAT';

interface GetRoomsNumberBreadcrumbSegment {
    t: Translator;
    basicUrlParams: BasicUrlParams;
    adInformations: AdInformation[];
    market: Market;
    location?: string;
}

const getRoomsNumberBreadcrumbSegment = ({
    t,
    basicUrlParams,
    adInformations,
    market,
    location,
}: GetRoomsNumberBreadcrumbSegment): {
    roomsNumberBreadcrumbSegment: AdBreadcrumb[];
    roomsNumber: RoomsNumber[] | undefined;
} => {
    const roomsNumberNumericValue = adInformations.find((item) => item.label === 'rooms_num')?.values[0];
    const roomsNumberStringValue = roomsNumberNumericValue
        ? getRoomNumberByNumeralValue(roomsNumberNumericValue)
        : undefined;

    let roomsNumber;
    let roomsNumberBreadcrumbSegment: AdBreadcrumb[] = [];

    if (
        roomsNumberNumericValue &&
        roomsNumberStringValue &&
        getSegmentByValue(roomsNumberStringValue, SEGMENT_TYPE.roomsNumber, false)
    ) {
        roomsNumber = [roomsNumberStringValue];
        roomsNumberBreadcrumbSegment = [
            {
                label: t(`frontend.ads.breadcrumbs.rooms-value-${roomsNumberNumericValue}`),
                locative: '',
                url: buildSearchUrl({
                    ...basicUrlParams,
                    market,
                    roomsNumber,
                    location,
                }),
            },
        ];
    }

    return {
        roomsNumber,
        roomsNumberBreadcrumbSegment,
    };
};

interface GetUseTypesBreadcrumbSegment {
    t: Translator;
    basicUrlParams: BasicUrlParams;
    adInformations: AdInformation[];
    market: Market;
    location?: string;
}

const getUseTypesBreadcrumbSegment = ({
    t,
    basicUrlParams,
    adInformations,
    market,
    location,
}: GetUseTypesBreadcrumbSegment): {
    useTypesBreadcrumbSegment: AdBreadcrumb[];
    useTypes: UseTypes[];
} => {
    let useTypes: UseTypes[] = [];
    let useTypesBreadcrumbSegment: AdBreadcrumb[] = [];

    if (
        basicUrlParams.estate === ESTATE.commercialProperty &&
        getSegmentByValue('OFFICE', SEGMENT_TYPE.useTypes, false) &&
        adInformations.some(
            (item) => item.label === 'use_types' && item.values.some((value) => value.split('::')[1] === 'office'),
        )
    ) {
        useTypes = ['OFFICE'];

        useTypesBreadcrumbSegment = [
            {
                label: t('frontend.search.listing.meta-title-estate-transaction', {
                    estateLabel: t(getEstateTranslationKeyPrefix(useTypes[0])),
                    transactionLabel: t(getTransactionTranslationKeyPrefix(basicUrlParams.transaction)),
                }),
                locative: '',
                url: buildSearchUrl({
                    ...basicUrlParams,
                    market,
                    useTypes,
                    location,
                }),
            },
        ];
    }

    return {
        useTypes,
        useTypesBreadcrumbSegment,
    };
};

export const generateLocationBreadcrumbs = ({
    locations,
    location,
    lang,
    currentLabel,
    transaction,
    estate,
    topLabel,
    market,
    t,
    adInformations,
    availableMarketsBreadcrumb,
}: GenerateLocationBreadcrumbsProps): AdBreadcrumb[] => {
    const basicUrlParams: BasicUrlParams = {
        lang,
        transaction,
        estate,
        isInternalRoute: false,
    };
    const typeBreadcrumb: AdBreadcrumb = {
        label: topLabel,
        locative: '',
        url: buildSearchUrl({ ...basicUrlParams, location }),
    };

    const isPrimaryMarket = market === 'PRIMARY';
    const isPrimaryOrSecondaryMarket = isPrimaryMarket || market === 'SECONDARY';
    const isInvestment = estate === ESTATE.investment;
    const isAvailableMarket = availableMarketsBreadcrumb.includes(market);

    const marketBreadcrumb =
        isAvailableMarket && isPrimaryOrSecondaryMarket && !isInvestment
            ? [
                  {
                      label: isPrimaryMarket
                          ? t('frontend.search.breadcrumbs.primary-market')
                          : t('frontend.search.breadcrumbs.secondary-market'),
                      locative: '',
                      url: buildSearchUrl({ ...basicUrlParams, market, location }),
                  },
              ]
            : [];

    const { roomsNumberBreadcrumbSegment, roomsNumber } = getRoomsNumberBreadcrumbSegment({
        t,
        basicUrlParams,
        adInformations,
        market,
        location,
    });

    const { useTypesBreadcrumbSegment, useTypes } = getUseTypesBreadcrumbSegment({
        t,
        basicUrlParams,
        adInformations,
        market,
        location,
    });

    const locationBreadcrumbs: AdBreadcrumb[] = locations.map((location) => {
        const url = buildSearchUrl({
            ...basicUrlParams,
            market,
            roomsNumber,
            useTypes,
            location: location.id,
        });

        return {
            label: location.name,
            locative: location.name,
            url,
        };
    });

    const currentBreadcrumb: AdBreadcrumb = {
        label: currentLabel,
        locative: '',
        url: '',
    };

    return [
        typeBreadcrumb,
        ...marketBreadcrumb,
        ...roomsNumberBreadcrumbSegment,
        ...useTypesBreadcrumbSegment,
        ...locationBreadcrumbs,
        currentBreadcrumb,
    ];
};
