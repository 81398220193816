import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

import { Title } from '../AdHeader/AdHeader.theme';

export const Container = styled.div`
    width: 100%;
    border-width: ${theme.borderWidths[1]};
    border-style: ${theme.borderStyles.solid};
    border-color: ${({ theme }): CSSProperties['borderColor'] =>
        theme.deprecated.domains.ad.expiredAdContent.borderColor};
`;

export const ImageWrapper = styled.picture`
    display: block;
    position: relative;
    width: 100%;
    height: 240px;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(0deg, rgb(217 217 217 / 20%) 0%, rgb(217 217 217 / 20%) 100%);
    }

    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media ${theme.mediaQueries.lgMin} {
        height: 440px;
    }
`;

export const TextWrapper = styled.div`
    display: grid;
    grid-row-gap: ${theme.space[16]};
    grid-template-areas:
        'title'
        'location'
        'price';
    grid-template-rows: repeat(3, auto);
    padding: ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        grid-row-gap: 0;
        grid-template-areas:
            'title price'
            'location price';
        grid-template-columns: 1fr auto;
    }
`;

export const StyledTitle = styled(Title)`
    grid-area: title;
    color: ${({ theme }): CSSProperties['color'] => theme.deprecated.domains.ad.expiredAdContent.color};
`;

export const LocationWrapper = styled.div`
    display: flex;
    grid-area: location;
    align-items: center;
    align-self: flex-end;
`;

export const LocationAddress = styled.span`
    color: ${({ theme }): CSSProperties['color'] => theme.deprecated.domains.ad.expiredAdContent.color};
    font-size: ${theme.typography.p3.fontSize};
`;

export const LocationIcon = styled(Icon)`
    margin-right: ${theme.space[8]};
    color: ${({ theme }): CSSProperties['color'] => theme.deprecated.domains.ad.expiredAdContent.color};
`;
