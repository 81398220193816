import { REPORT_AD_MODAL_ID } from '@domains/ad/components/ReportAdModal/consts/reportAdModalConst';
import type { Ad } from '@domains/ad/types/Ad';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import dynamic from 'next/dynamic';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';

import { ReportAdNexusButton, ReportAdNexusIcon, ReportTextButton } from './AdReportAd.theme';

interface Props {
    ad: Ad;
    shouldShowNexusVariant?: boolean;
}

const LazyReportAdModal = dynamic(() => import('@domains/ad/components/ReportAdModal/ReportAdModal'), { ssr: false });

const REPORT_AD_PARAMETER = 'reportAd';

export const AdReportAd: FC<Props> = ({ ad, shouldShowNexusVariant }) => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { openDialog, isOpen: isReportAdModalOpen } = useDialogState(REPORT_AD_MODAL_ID);

    const handleReportAd = useCallback(
        (trackingData: Record<string, unknown>) => {
            openDialog();

            trackEvent('ad_report_click', trackingData);
        },
        [openDialog, trackEvent],
    );

    useEffect(() => {
        if (window.location.hash.includes(REPORT_AD_PARAMETER)) {
            handleReportAd({ touch_point_button: 'native_app_url' });
            window.location.hash = '';
        }
    }, [handleReportAd]);

    if (shouldShowNexusVariant) {
        return (
            <>
                <ReportAdNexusButton
                    variant="flat"
                    prefixIcon={ReportAdNexusIcon}
                    onClick={(): void => handleReportAd({ touch_point_button: 'ad_page' })}
                >
                    {t('frontend.ad.report-button.label')}
                </ReportAdNexusButton>
                {isReportAdModalOpen && <LazyReportAdModal adId={ad.id} />}
            </>
        );
    }

    return (
        <>
            <ReportTextButton
                variant="secondary"
                onClick={(): void => handleReportAd({ touch_point_button: 'ad_page' })}
            >
                {t('frontend.ad.report.button')}
            </ReportTextButton>
            {isReportAdModalOpen && <LazyReportAdModal adId={ad.id} />}
        </>
    );
};
