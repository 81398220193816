export const CONTACT_FORM_TYPE = {
    bottomAdPage: 'bottom_ad_page',
    suggestPrice: 'suggest_price',
    adNavbar: 'ad_navbar',
    stickyForm: 'sticky_form',
    mainContactForm: 'main_contact_form',
    missingAdParameters: 'missing_ad_parameters',
    galleryFullScreen: 'gallery_full_screen',
    mobileStickyFooter: 'mobile_sticky_footer',
    savedAdsPage: 'saved_ads_page',
    listingPage: 'listing_page',
    askForPrice: 'ask_for_price',
} as const;
