import { EXPERIMENT } from '@domains/ad/consts/experiment';
import type { Variables } from '@domains/ad/graphql/queries/RecommendedAdsQuery';
import { RECOMMENDED_ADS_QUERY } from '@domains/ad/graphql/queries/RecommendedAdsQuery';
import type { RecommendedAd } from '@domains/ad/types/Ad';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { useHandleGraphqlError } from '@lib/graphql/hooks/useHandleGraphqlError';
import { UPPER_CASE_LOCALE } from '@lib/i18n/types/locale';
import { useQuery } from 'urql';

export const useRecommendedAdverts = (
    advertId: number,
): {
    fetching: boolean;
    recommendedAdverts: RecommendedAd[];
} => {
    const { lang } = useSiteSettings();
    const { getExperimentVariant } = useExperiments();
    const eure29774Variant = getExperimentVariant(EXPERIMENT.eure29774);

    const variables: Variables = {
        input: {
            adId: advertId,
            lang: UPPER_CASE_LOCALE[lang],
            limit: 4,
            model: 'COOL',
        },
    };

    if (eure29774Variant && eure29774Variant !== 'a') {
        variables.input.experiment = `${EXPERIMENT.eure29774.toLowerCase()}@${eure29774Variant}`;
    }

    const [{ data, fetching, error, operation }] = useQuery({
        query: RECOMMENDED_ADS_QUERY,
        variables,
    });

    useHandleGraphqlError({ descriptor: 'RecommendedAdsQuery', error, operation });

    return {
        fetching,
        recommendedAdverts: data?.recommendedAds?.items || [],
    };
};
