const CURRENCY_ISO: Record<string, string> = {
    '€': 'EUR',
    zł: 'PLN',
    // eslint-disable-next-line @typescript-eslint/naming-convention -- Currency symbol
    L: 'RON',
    $: 'USD',
    грн: 'UAH',
};

/**
 * Converts symbol to currency ISO code
 * @param currencySymbol
 */
export const getCurrencyIsoCode = (currencySymbol: string): string => {
    return CURRENCY_ISO[currencySymbol] || currencySymbol;
};
