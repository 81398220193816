import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useCallback } from 'react';

const TODAY_DIFF_LESS_THAN_MS = 86400000; // 24 * 60 * 60 * 1000
const TOMORROW_DIFF_LESS_THAN_MS = 172800000; // 24 * 60 * 60 * 1000 * 2

export type FormatOpenDay = (rawDate: string, isNumeric?: boolean) => string;

export const useOpenDateFormatter = (): FormatOpenDay => {
    const [t] = useTranslations();
    const { lang } = useSiteSettings();

    const formatOpenDay = useCallback(
        (rawDate: string, isNumeric?: boolean): string => {
            const date = new Date(`${rawDate}T23:59:59`);
            const dateDiffInMs = date.getTime() - Date.now();

            // Here we don't care about the timezone, because we do date manipulation only, using the client TZ
            if (dateDiffInMs < TODAY_DIFF_LESS_THAN_MS) {
                return t('frontend.global.date.today');
            }

            if (dateDiffInMs < TOMORROW_DIFF_LESS_THAN_MS) {
                return t('frontend.global.date.tomorrow');
            }

            if (isNumeric) {
                return new Intl.DateTimeFormat(lang).format(date);
            }

            return new Intl.DateTimeFormat(lang, {
                day: 'numeric',
                month: 'long',
            }).format(date);
        },
        [t, lang],
    );

    return formatOpenDay;
};
