import type { Address } from '@type/location/address';
import type { AdvertLocationDetails } from '@type/location/advertLocation';

type LocationType = keyof Address | 'location';

export const getIdForLocationType = (location: AdvertLocationDetails, locationType: LocationType): string[] | null => {
    if (locationType === 'location') {
        return location.id ? [location.id] : [];
    }

    const addressDetail = location.address[locationType];

    if (typeof addressDetail === 'string') {
        return null;
    }

    return addressDetail?.id ? [addressDetail.id] : [];
};
