import type { Ad } from '@domains/ad/types/Ad';
import { getLocalisedLink } from '@domains/shared/components/LocalLink/LocalLink';
import { AD_PAGE_TYPE } from '@domains/shared/consts/adPageType';
import { CATEGORY_ID } from '@domains/shared/types/categoryId';
import type { Locale } from '@lib/i18n/types/locale';

interface Props {
    ad: Ad;
    lang: Locale;
}

export const getCanonicalLink = ({ ad, lang }: Props): string | null => {
    if (!ad.url) {
        return null;
    }

    const isInvestment = ad.category.id === CATEGORY_ID.investment;

    if (isInvestment) {
        const adPageTypeUrlPart = AD_PAGE_TYPE.investment;
        const { href } = getLocalisedLink({
            href: `/${lang}/${adPageTypeUrlPart}/`,
        });

        return ad.url.replace(/(\/(\w+\/){2})/, href);
    }

    return ad.url;
};
