import { useGetAdLocationAddress } from '@domains/ad/hooks/useGetAdLocationAddress';
import type { Ad } from '@domains/ad/types/Ad';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { useTheme } from '@nexus/lib-react/dist/theme/emotionUtils';
import type { FC } from 'react';

import {
    Container,
    ImageWrapper,
    LocationAddress,
    LocationIcon,
    LocationWrapper,
    StyledTitle,
    TextWrapper,
} from './ExpiredAdContent.theme';

export const ExpiredAdContent: FC<{ ad: Ad }> = ({ ad }) => {
    const { location, title, images } = ad;
    const locationAddress = useGetAdLocationAddress(location);
    const theme = useTheme();
    const adImage = images[0];

    return (
        <Container>
            {adImage ? (
                <ImageWrapper>
                    <source media={theme.mediaQueries.xlMin} srcSet={adImage.large} />
                    <source media={theme.mediaQueries.lgMin} srcSet={adImage.medium} />
                    <source media={theme.mediaQueries.mdMin} srcSet={adImage.small} />
                    <img src={adImage.small} alt={title} />
                </ImageWrapper>
            ) : null}
            <TextWrapper>
                <StyledTitle data-cy="expired-ad-title">{title}</StyledTitle>
                {locationAddress ? (
                    <LocationWrapper data-cy="expired-ad-location">
                        <LocationIcon icon={faMapMarkerAlt} aria-hidden="true" width="11" height="14" />
                        <LocationAddress>{locationAddress}</LocationAddress>
                    </LocationWrapper>
                ) : null}
            </TextWrapper>
        </Container>
    );
};
