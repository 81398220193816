import { URL_LOCATION_FILLER } from '@domains/shared/consts/urlLocationFiller';
import type { Locale } from '@lib/i18n/types/locale';
import type { Agency } from '@type/ad/advertAgency';
import type { AgencyOwner } from '@type/ad/attributes/advertOwner';
import { SEGMENT_TYPE } from '@type/search/segmentTypes';
import { buildSearchUrl } from '@widgets/search/buildSearchUrl';
import { getSegmentsByType } from '@widgets/search/getSegment';

interface Props {
    agency: Pick<Agency, 'id' | 'url'> | Pick<AgencyOwner, 'id' | 'url'> | null | undefined;
    lang: Locale;
    estate: string;
    transaction: string;
}

export const hasSellerId = (url: string): boolean => url.includes('sellerId=');

export const getAgencyUrl = ({ agency, estate, transaction, lang }: Props): string | undefined => {
    if (!agency) {
        return undefined;
    }

    if (agency.url && !hasSellerId(agency.url)) {
        return agency.url;
    }

    const isInvestment = transaction === 'INVESTMENT';
    const fixedTransaction = isInvestment ? 'SELL' : transaction;
    const fixedEstate = isInvestment ? 'INVESTMENT' : estate;

    // if the estate type or the transaction type doesn't match any of the possible URL segments of the listing page, then return the passed URL
    if (
        !getSegmentsByType(SEGMENT_TYPE.estate)?.some((segment) => segment.value === fixedEstate) ||
        !getSegmentsByType(SEGMENT_TYPE.transaction)?.some((segment) => segment.value === fixedTransaction)
    ) {
        return agency.url || undefined;
    }

    if (!agency.url || hasSellerId(agency.url)) {
        return buildSearchUrl({
            lang,
            transaction: fixedTransaction,
            estate: fixedEstate,
            location: URL_LOCATION_FILLER.allLocations.label,
            queryParameters: {
                sellerId: agency.id.toString(),
            },
        });
    }

    return agency.url;
};
