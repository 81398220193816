import { SITE_CONFIG } from '@config/siteConfig';
import { getCanonicalLink } from '@domains/ad/helpers/getCanonicalLink';
import { shouldEnableIndexingByMetaRobots } from '@domains/ad/helpers/shouldEnableIndexingByMetaRobots';
import type { Ad } from '@domains/ad/types/Ad';
import { getLocalisedLink } from '@domains/shared/components/LocalLink/LocalLink';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Locale } from '@lib/i18n/types/locale';
import Head from 'next/head';
import type { FC } from 'react';

interface Props {
    ad: Ad;
    relativeUrl: string;
    lang: Locale;
}

function getSeoTitle(ad: Ad, sellWithUs: boolean, translation: string): string {
    let seoTitle = ad.seo.title;

    if (seoTitle && ad.exclusiveOffer && sellWithUs) {
        seoTitle = `${translation} - ${ad.seo.title}`;
    }

    return seoTitle;
}

export const AdHeadMetadata: FC<Props> = ({ ad, relativeUrl, lang }) => {
    const [t] = useTranslations();
    // Get all languages website can be displayed in
    const { links, sellWithUs } = SITE_CONFIG.metadata;
    const alternateLinks = links.filter(({ rel }) => rel === 'alternate');
    // Get core canonical url so We can build alternate link on it
    const defaultCanonical = links.find(({ rel }) => rel === 'canonical')?.href;
    const getAlternate = (lang: string): string => {
        // Split url so we can replace lang parameter
        const splittedUrl = relativeUrl.split('/');
        const { href } = getLocalisedLink({
            href: `${lang}/ad`,
        });

        return `${defaultCanonical}${href}/${splittedUrl[splittedUrl.length - 1]}`;
    };

    const seoTitle = getSeoTitle(ad, sellWithUs, t('frontend.ad-head-metadata.seo-title.sell-with-us-prefix'));

    const canonicalLink = getCanonicalLink({
        ad,
        lang,
    });

    return (
        <Head>
            {ad.seo.title ? (
                <>
                    <title>{ad.seo.title}</title>
                    <meta property="og:title" content={seoTitle} key="meta_property_og:title" />
                </>
            ) : null}
            {ad.seo.description ? (
                <>
                    <meta name="description" content={ad.seo.description} key="meta_name_description" />
                    <meta property="og:description" content={ad.seo.description} key="meta_name_og:description" />
                </>
            ) : null}
            {canonicalLink ? <link rel="canonical" href={canonicalLink} key="link_canonical" /> : null}
            {ad.images[0]?.medium ? (
                <meta property="og:image" content={ad.images[0].medium} key="meta_property_og:image" />
            ) : null}
            {relativeUrl ? (
                <meta
                    property="og:url"
                    content={`${relativeUrl}?utm_source=dark&utm_medium=social&utm_campaign=open-graph`}
                    key="meta_property_og:url"
                />
            ) : null}
            {alternateLinks.map(({ extraParams }) =>
                extraParams?.hrefLang ? (
                    <link
                        rel="alternate"
                        hrefLang={extraParams.hrefLang}
                        href={`${getAlternate(extraParams.hrefLang)}`}
                        key={`link_alternate_${extraParams.hrefLang}`}
                    />
                ) : null,
            )}
            <meta
                name="robots"
                content={`${shouldEnableIndexingByMetaRobots(relativeUrl) ? 'index' : 'noindex'}, follow`}
                key="meta_name_robots"
            />
        </Head>
    );
};
