import { css } from '@emotion/react';
import type { CSSProperties, SerializedStyles } from '@emotion/serialize';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LINE_HEIGHT, SIZE, WEIGHT } from '@lib/styles/partials/typography';

// hasInstallmentAdInNewLine is only used in InvestmentUnitHeader
const Header = styled.header<{ hasInstallmentAdInNewLine?: boolean }>`
    display: grid;
    padding-top: 16px;
`;

export const DefaultHeader = styled(Header)`
    grid-template-areas:
        'headline headline'
        'location location'
        'price ad'
        'extra .'
        'exclusiveOfferTag exclusiveOfferTag';
    grid-template-columns: 1fr 1fr;

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-areas:
            'headline headline price'
            'location location extra'
            '. . ad'
            'exclusiveOfferTag exclusiveOfferTag .';
    }
`;

export const HeaderWithSuggestPrice = styled(Header)`
    grid-template-areas:
        'headline headline'
        'location location'
        'price ad'
        'extra .'
        'suggestPrice .'
        'exclusiveOfferTag exclusiveOfferTag';
    grid-template-columns: 1fr 1fr;

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-areas:
            'headline headline price'
            'location location extra'
            '. . suggestPrice'
            '. . ad'
            'exclusiveOfferTag exclusiveOfferTag .';
    }
`;

export const HeaderWithSuggestPriceWithoutAdUnit = styled(Header)`
    grid-template-areas:
        'headline headline'
        'location location'
        'price .'
        'extra .'
        'suggestPrice .'
        'exclusiveOfferTag exclusiveOfferTag';
    grid-template-columns: 1fr 1fr;

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-areas:
            'headline headline price'
            'location location extra'
            '. . suggestPrice'
            'exclusiveOfferTag exclusiveOfferTag .';
    }
`;

export const InvestmentHeaderWithLogo = styled(Header)`
    grid-template-areas:
        'headline headline'
        'location location'
        'developer developer'
        'price ad'
        'extra .'
        'exclusiveOfferTag exclusiveOfferTag';

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-areas:
            'headline headline price'
            'location location extra'
            'developer developer ad'
            'exclusiveOfferTag exclusiveOfferTag .';
    }
`;

export const InvestmentHeader = styled(Header)`
    grid-template-areas:
        'headline headline'
        'location location'
        'developer .'
        'price ad'
        'extra .'
        'exclusiveOfferTag exclusiveOfferTag';

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-areas:
            'headline headline price'
            'location location extra'
            'developer . ad'
            'exclusiveOfferTag exclusiveOfferTag .';
    }
`;

export const InvestmentUnitHeader = styled(Header)`
    ${({ hasInstallmentAdInNewLine }): SerializedStyles => {
        if (hasInstallmentAdInNewLine) {
            return css`
                grid-template-areas:
                    'headline headline'
                    'location location'
                    'tag tag'
                    'specialOffer specialOffer'
                    'price price'
                    'extra .'
                    'suggestPrice .'
                    'ad ad'
                    'exclusiveOfferTag exclusiveOfferTag';
            `;
        }

        return css`
            grid-template-areas:
                'headline headline'
                'location location'
                'tag tag'
                'specialOffer specialOffer'
                'price ad'
                'extra .'
                'suggestPrice .'
                'exclusiveOfferTag exclusiveOfferTag';
        `;
    }};

    @media (min-width: ${BREAKPOINT.xs}) {
        grid-template-areas:
            'headline headline'
            'location location'
            'tag tag'
            'specialOffer specialOffer'
            'price ad'
            'extra .'
            'suggestPrice .'
            'exclusiveOfferTag exclusiveOfferTag';
    }

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-areas:
            'headline headline price'
            'location location extra'
            '. . suggestPrice'
            'tag tag ad'
            'specialOffer specialOffer specialOffer'
            'exclusiveOfferTag exclusiveOfferTag .';
    }
`;

export const Category101PostingFormHeader = styled(Header)`
    grid-template-areas:
        'headline headline'
        'location location'
        'price negotiableBadge'
        'extra extra'
        'additionalCosts additionalCosts'
        'ad ad';
    grid-template-columns: auto 1fr;

    @media (min-width: ${BREAKPOINT.md}) {
        grid-template-areas:
            'headline headline headline headline'
            'location location location location'
            'price extra negotiableBadge ad'
            'additionalCosts additionalCosts additionalCosts additionalCosts';
        grid-template-columns: auto auto auto 1fr;
    }
`;

/* max-width: when we have long string it won't break without set width */
export const Title = styled.h1`
    grid-area: headline;
    max-width: calc(100vw - 32px);
    margin: 0;
    color: ${({ theme }): CSSProperties['color'] => theme.deprecated.atoms.heading.h1.color};
    font-size: ${SIZE.h5};
    font-weight: ${WEIGHT.bold};
    line-height: ${LINE_HEIGHT.x1};
    word-wrap: break-word;
`;

export const Extra = styled.div<{ isCategory101PostingFormHeader: boolean }>`
    grid-area: extra;
    margin-bottom: 16px;
    color: ${({ theme }): CSSProperties['color'] => theme.deprecated.domains.ad.adHeader.extras.color};
    font-weight: ${WEIGHT.semibold};
    text-align: left;

    @media (min-width: ${BREAKPOINT.md}) {
        align-self: end;
        margin: ${({ isCategory101PostingFormHeader }): CSSProperties['margin'] =>
            isCategory101PostingFormHeader ? '0 0 4px 8px' : '0'};
        text-align: ${({ isCategory101PostingFormHeader }): CSSProperties['textAlign'] =>
            isCategory101PostingFormHeader ? 'left' : 'right'};
    }
`;

export const AdUnit = styled.div<{ isCategory101PostingFormHeader: boolean; isInNewLine?: boolean }>`
    grid-area: ad;
    place-self: center
        ${({ isCategory101PostingFormHeader, isInNewLine }): CSSProperties['justifySelf'] =>
            isCategory101PostingFormHeader || isInNewLine ? 'left' : 'right'};
    width: 170px;
    margin: ${({ isInNewLine }): CSSProperties['margin'] => (isInNewLine ? '0 0 12px 0' : 'auto 0')};
    overflow: hidden;

    @media (min-width: ${BREAKPOINT.xs}) and (max-width: ${BREAKPOINT.sm}) {
        margin: auto 0;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        place-self: ${({ isCategory101PostingFormHeader }): CSSProperties['alignSelf'] =>
                isCategory101PostingFormHeader ? 'end' : 'center'}
            right;
        margin: ${({ isCategory101PostingFormHeader }): CSSProperties['margin'] =>
            isCategory101PostingFormHeader ? '0 0 4px 0' : '0'};
        padding-top: 8px;
        text-align: center;
    }
`;

export const PriceBeforeDiscount = styled.span`
    margin-left: 8px;
    color: ${({ theme }): CSSProperties['color'] =>
        theme.deprecated.domains.ad.projectAdHeader.priceBeforeDiscount.price.color};
    font-size: ${SIZE.p4};
    font-weight: ${WEIGHT.regular};
    text-align: left;
    text-decoration: line-through;

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.p1};
    }
`;

export const PricePerMBeforeDiscount = styled(PriceBeforeDiscount)`
    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.p4};
    }
`;
