import { gql } from 'urql';

export const GET_AD_OWNER_QUERY = gql`
    query GetAdOwnerQuery($ownerId: ID!) {
        owner(id: $ownerId) {
            __typename
            ... on AgencyOwner {
                sellerBadges {
                    id
                }
            }
            ... on DeveloperOwner {
                sellerBadges {
                    id
                }
            }
        }
    }
`;
