import type { RecommendedAd } from '@domains/ad/types/Ad';
import type { Locale } from '@lib/i18n/types/locale';
import { gql } from '@urql/core';

interface Data {
    recommendedAds: {
        items: RecommendedAd[];
    };
}

export interface Variables {
    input: {
        adId: number;
        lang: Uppercase<Locale>;
        limit: number;
        model?: 'RP3BETA' | 'ITEM2VEC' | 'COOL';
        experiment?: string;
    };
}

export const RECOMMENDED_ADS_QUERY = gql<Data, Variables>`
    query RecommendedAdsQuery($input: RecommendedAdsInput!) {
        recommendedAds(input: $input) {
            ... on RecommendedAds {
                items {
                    adId
                    adCategory {
                        type
                    }
                    characteristics {
                        currency
                        key
                        value
                        localizedValue
                    }
                    images {
                        thumbnail
                        small
                    }
                    title
                    url
                    location: locationDetails {
                        mapDetails {
                            radius
                        }
                        address {
                            street {
                                name
                            }
                        }
                        reverseGeocoding {
                            locations {
                                fullName
                            }
                        }
                    }
                }
            }
        }
    }
`;
