export const ROOMS_NUMBER_VARIANTS = [
    'ONE',
    'TWO',
    'THREE',
    'FOUR',
    'FIVE',
    'SIX',
    'SEVEN',
    'EIGHT',
    'NINE',
    'TEN',
    'MORE',
    'SIX_OR_MORE',
] as const;
const STORIA_ROOMS_NUMBER_VARIANTS = ['TEN_OR_MORE'] as const;
export type RoomsNumber = (typeof ROOMS_NUMBER_VARIANTS)[number] | (typeof STORIA_ROOMS_NUMBER_VARIANTS)[number];
