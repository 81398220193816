import { AD_CONFIG } from '@config/ad/adConfig';
import { SEARCH_RESULTS_FOR_SIMILAR_ADS_QUERY } from '@domains/ad/graphql/queries/SearchResultsForSimilarAdsQuery';
import type { Ad, RecommendedAd } from '@domains/ad/types/Ad';
import { generateAdvertLink } from '@domains/shared/helpers/generateAdvertLink';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useHandleGraphqlError } from '@lib/graphql/hooks/useHandleGraphqlError';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';
import type { Money } from '@type/pricing/money';
import { ESTATE } from '@type/search/filters/estate';
import { ROOMS_NUMBER_VARIANTS } from '@type/search/filters/roomsNumber';
import { useQuery } from 'urql';

const DEFAULT_FILTER_RANGE = 0.15;
const DEFAULT_SEARCH_LIMIT = 8;

const mapAdValuesToFilter = (
    value: number,
    valueType: 'MIN' | 'MAX',
    range = DEFAULT_FILTER_RANGE,
): number | undefined => {
    const operation =
        valueType === 'MIN'
            ? Math.round(value - Math.round(value * range))
            : Math.round(value + Math.round(value * range));

    return value ? operation : undefined;
};

const getCharacteristicFromSearchAd = (
    totalPrice: Money | null,
    areaInSquareMeters: number | null,
    pricePerSquareMeter: Money | null,
): AdvertCharacteristic[] => {
    const adCharacteristicDefaultFields = {
        label: '',
        localizedValue: '',
        suffix: '',
        currency: '',
        __typename: '',
    };

    const advertCharacteristic = [
        {
            ...adCharacteristicDefaultFields,
            key: 'm',
            value: String(areaInSquareMeters),
        },
    ];

    if (totalPrice) {
        advertCharacteristic.push({
            ...adCharacteristicDefaultFields,
            key: 'price',
            value: String(totalPrice.value),
            currency: totalPrice.currency,
        });
    }

    if (pricePerSquareMeter) {
        advertCharacteristic.push({
            ...adCharacteristicDefaultFields,
            key: 'price_per_m',
            value: String(pricePerSquareMeter.value),
            currency: pricePerSquareMeter.currency,
        });
    }

    return advertCharacteristic;
};

const { areRecommendedAdsOnExpiredAdPageEnabled } = AD_CONFIG;

export const useRecommendedAdvertsFromSearchResults = (
    ad: Ad,
): {
    fetching: boolean;
    recommendedAdverts: RecommendedAd[];
} => {
    const { lang } = useSiteSettings();

    const { adCategory, characteristics, location } = ad;
    const { name: estate, type: transaction } = adCategory;
    const locationId =
        location?.reverseGeocoding?.locations?.[location.reverseGeocoding.locations.length - 1]?.id || null;

    const filterLocations = {
        byDomainId: [
            {
                domainId: locationId,
            },
        ],
    };

    const price = Number(characteristics.find((c) => c.key === 'price')?.value);
    const rooms = Number(characteristics.find((c) => c.key === 'rooms_num')?.value);
    const area = Number(characteristics.find((c) => c.key === 'm')?.value);

    const additionalFilters = {
        priceMin: mapAdValuesToFilter(price, 'MIN'),
        priceMax: mapAdValuesToFilter(price, 'MAX'),
        areaMin: mapAdValuesToFilter(area, 'MIN'),
        areaMax: mapAdValuesToFilter(area, 'MAX'),
        roomsNumber: rooms ? [rooms > 10 ? ROOMS_NUMBER_VARIANTS[10] : ROOMS_NUMBER_VARIANTS[rooms - 1]] : undefined,
    };

    const filterAttributes = {
        estate,
        transaction,
        ...additionalFilters,
    };

    const [{ data, fetching, error, operation }] = useQuery({
        query: SEARCH_RESULTS_FOR_SIMILAR_ADS_QUERY,
        variables: {
            lang: lang.toUpperCase(),
            filterAttributes,
            filterLocations,
            sort: {
                by: 'DEFAULT',
                direction: 'DESC',
            },
            // We need to query more records in case some data will be missing
            page: { current: 0, limit: DEFAULT_SEARCH_LIMIT },
        },
        pause: !areRecommendedAdsOnExpiredAdPageEnabled,
    });

    const recommendedAdverts =
        data?.searchAds && 'items' in data.searchAds
            ? data.searchAds.items
                  .filter(({ developmentTitle, title, slug }) => slug && (developmentTitle || title))
                  .map(
                      ({
                          id,
                          totalPrice,
                          images,
                          areaInSquareMeters,
                          pricePerSquareMeter,
                          developmentTitle,
                          title,
                          slug,
                          estate,
                      }) => ({
                          adId: String(id),
                          characteristics: getCharacteristicFromSearchAd(
                              totalPrice,
                              areaInSquareMeters,
                              pricePerSquareMeter,
                          ),
                          images: images.map((img) => ({
                              thumbnail: img.medium,
                          })),
                          title: developmentTitle || title,
                          url: generateAdvertLink({
                              isInvestment: estate === ESTATE.investment,
                              slug,
                          }),
                      }),
                  )
                  .slice(0, 4)
            : [];

    useHandleGraphqlError({
        descriptor: 'useRecommendedAdvertsFromSearchResults: SearchResultsQuery for recommended ads',
        error,
        operation,
    });

    return {
        fetching,
        recommendedAdverts,
    };
};
