import type { ExtendedAdInformationLinks } from '@domains/ad/components/Table/setAdInformationLink';
import { getQuarterFromDate } from '@domains/ad/helpers/getQuarterFromDate';
import {
    getRoomNumberByNumeralValue,
    getRoomNumberTypologyByStringValue,
} from '@domains/shared/helpers/getRoomNumberByTheString';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Locale } from '@lib/i18n/types/locale';

const YEAR_LABELS = new Set(['construction_year', 'build_year']);
const DECIMAL_COMMA_SEPARATED_NUMBER_REGEX = /^(\d+(?:,\d+)?)$/;
export const getDescription = ({
    isInvestment,
    label,
    lang,
    shouldHaveNewTranslationKeys,
    shouldUseTypologyInsteadOfNumberOfRooms,
    t,
    translationPrefix,
    unit,
    values,
    links,
}: {
    isInvestment: boolean;
    label: string;
    lang: Locale;
    shouldHaveNewTranslationKeys: boolean;
    shouldUseTypologyInsteadOfNumberOfRooms?: boolean;
    t: Translator;
    translationPrefix: string;
    unit: string;
    values: string[];
    links?: ExtendedAdInformationLinks;
}): string | undefined => {
    const firstValue = values[0];

    if (label === 'number_of_user_license') {
        return firstValue; // we don't want to format this number
    }

    const isNumericValue =
        values.length === 1 &&
        (!Number.isNaN(Number(firstValue)) || DECIMAL_COMMA_SEPARATED_NUMBER_REGEX.test(firstValue));

    const isYearLabel = isNumericValue && YEAR_LABELS.has(label);

    if (isYearLabel) {
        return firstValue;
    }

    if (isNumericValue) {
        const numericValue = Number.isNaN(Number(firstValue)) ? firstValue : Number(firstValue).toLocaleString(lang);
        let text;

        if (label === 'rooms_num' && shouldUseTypologyInsteadOfNumberOfRooms) {
            const roomsNumber = getRoomNumberByNumeralValue(numericValue);
            text = roomsNumber
                ? t('frontend.shared.ad.rooms-typology', {
                      value: getRoomNumberTypologyByStringValue(roomsNumber),
                  })
                : numericValue + ` ${unit}`;
        } else {
            text = numericValue + ` ${unit}`;
        }

        return links && links[firstValue] ? `<C>[${text}](${links[firstValue]})<C>` : text;
    }

    // A range of values.
    if (isInvestment && values.length === 2 && unit !== '') {
        return `${firstValue} - ${values[1]} ${unit}`;
    }

    return getDescriptionForNotNumericValues({
        values,
        label,
        isInvestment,
        translationPrefix,
        t,
        shouldHaveNewTranslationKeys,
        links,
    });
};
const getDescriptionForNotNumericValues = ({
    isInvestment,
    label,
    shouldHaveNewTranslationKeys,
    t,
    translationPrefix,
    values,
    links,
}: {
    isInvestment: boolean;
    label: string;
    shouldHaveNewTranslationKeys: boolean;
    t: Translator;
    translationPrefix: string;
    values: string[];
    links?: ExtendedAdInformationLinks;
}): string | undefined => {
    /**
     * isFloorItem is needed for specific floor values [valueToTranslate, '/5']
     * where 5 is number of floors in building
     */
    const isFloorItem = label === 'floor';

    /* Ensure that the floorItem can be parsed properly */
    const isInvalidFloorItem = values.some((value) => typeof value !== 'string') || values[0] === '';

    /* Show 'no information' if no fence option was selected in the posting form */
    const isUnspecifiedFenceItem = label === 'fence' && values[0] === '::n';

    if (isInvalidFloorItem || isUnspecifiedFenceItem) {
        return;
    }

    const isDate = label === 'project_begin_date' || label === 'project_finish_date';

    return values
        .map((value) => {
            const link = links && links[value];

            // From and to dates are handled differently.
            if (isInvestment && isDate) {
                const formattedDate = getQuarterFromDate(values[0]);

                return t('frontend.ad.top-information.date-quarter', {
                    quarter: formattedDate.quarter,
                    year: formattedDate.year,
                });
            }

            // each value from the old schema that should be translated has '::' inside
            if (!value.includes('::')) {
                return link ? `<C>[${value}](${link})<C>` : value;
            }

            const translationValue = shouldHaveNewTranslationKeys
                ? value.split('::').pop()?.replace('_', '-')
                : value.split('::').join('-');

            const label = t(`${translationPrefix}value-${translationValue}`);

            return link ? `<C>[${label}](${link})<C>` : label;
        })
        .join(isFloorItem ? '' : ', ');
};
