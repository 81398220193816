import type { AdConfig } from '@config/ad/type';
import { SITE_CODE } from '@config/siteCode';

export * from '@config/ad/type';

export const AD_CONFIG: AdConfig = {
    adPageOptions: {
        showAgencyLicenseNumber: false,
        groupAdFeatures: true,
        subscribeLoginPrompt: true,
        shouldShowSaveAdButtonOnGallery: true,
        redirects: {
            defaultSearchPageRedirect: '/[lang]/wyniki/sprzedaz/mieszkanie/cala-polska#from404',
        },
    },
    adFinanceLinkConfig: {
        baseUrl: '/[lang]/finanse',
        utmSource: 'otodom',
        utmMedium: 'banner',
        utmCampaign: 'textlink',
        shouldUseAdditionalParams: true,
    },
    adMortgageSimulatorConfig: {
        type: SITE_CODE.pl,
        landingPageUrl: '/[lang]/finanse',
        mortgageBrowserPageUrl: '/[lang]/finanse/kredyty-hipoteczne',
        callRequestPageUrl: '/[lang]/finanse/zamow-rozmowe',
        utmSource: 'otodom',
        utmMedium: 'banner',
        utmCampaign: 'mortgage_simulator',
        mortgageDownpaymentPercentage: 0.2,
        mortgageInterest: 8.5,
        mortgageYears: 30,
        shouldUseAdditionalParams: true,
        currency: 'zł',
        shouldFetchBankRate: true,
    },
    adFinanceBanner: {
        url: '/[lang]/finanse/zamow-rozmowe',
        utmSource: 'otodom',
        utmMedium: 'adpage',
        utmCampaign: 'leadbanner',
    },
    mapId: {
        simplified: 'e43dc56687b0dbff',
    },
    shouldUseLocationService: true,
    availableMarketsBreadcrumb: ['PRIMARY', 'SECONDARY'],
    adInformationLinks: [
        { label: 'market', values: ['primary'] },
        { label: 'use_types', values: ['office'] },
        { label: 'rooms_num', values: ['2', '3', '4', '5'] },
    ],
    areRecommendedAdsOnExpiredAdPageEnabled: true,
    mortgageCalculator: {
        isEnabled: true,
        isAskAboutOfferButtonEnabled: true,
        isBikSectionEnabled: true,
        shouldShowRepaymentClarification: true,
    },
    adDetails: {
        shouldDisplayNumberOfUserLicense: false,
    },
    contactForm: {
        shouldPartiallyShowPhoneNumber: true,
        shouldUseCallCopy: true,
    },
    shouldShowFloorPlanLink: true,
};
