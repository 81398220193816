import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { FC } from 'react';

import { StyledLinkButton } from './SeeSimilarAdsLinkButton.theme';

interface Props {
    linkUrl: string;
}
export const SeeSimilarAdsLinkButton: FC<Props> = ({ linkUrl }) => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const trackSimilarAdsClick = (): void => {
        trackEvent('see_more_offers', {
            touch_point_button: 'similar_ads_recommendations',
        });
    };

    return (
        <LocalLink href={linkUrl} passHref>
            <StyledLinkButton
                data-cy="see-more-similar-ads-link"
                role="button"
                onClick={trackSimilarAdsClick}
                variant="secondary"
            >
                {t('frontend.ad.expired-ad.see-similar-ads.link')}
            </StyledLinkButton>
        </LocalLink>
    );
};
