import { GET_AD_OWNER_QUERY } from '@domains/ad/graphql/queries/AdOwnerQuery';
import type { AdOwner } from '@domains/ad/types/Ad';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useHandleGraphqlError } from '@lib/graphql/hooks/useHandleGraphqlError';
import { useQuery } from 'urql';

export const useAdOwner = (
    ownerId: number,
): {
    adOwner: AdOwner;
} => {
    const { featureFlags } = useSiteSettings();
    const { isBadgesFeatureEnabled } = featureFlags;

    const [{ data, error, operation }] = useQuery({
        query: GET_AD_OWNER_QUERY,
        variables: {
            ownerId,
        },
        pause: !isBadgesFeatureEnabled,
    });

    useHandleGraphqlError({ descriptor: 'owner', error, operation });

    return {
        adOwner: data?.owner || {},
    };
};
