import { AD_CONFIG } from '@config/ad/adConfig';
import { getLocationAddress } from '@domains/shared/helpers/getLocationAddress';
import type { AdvertLocationDetails } from '@type/location/advertLocation';
import { useCallback } from 'react';

import { formatAdvertAddress } from '../helpers/formatAdvertAddress';

export const useGetAdLocationAddress = (details: AdvertLocationDetails): string | undefined => {
    const { address, mapDetails, reverseGeocoding } = details;

    const sphereCallback = useCallback(() => formatAdvertAddress(address, mapDetails), [address, mapDetails]);

    if (!AD_CONFIG.shouldUseLocationService) {
        return sphereCallback();
    }

    return getLocationAddress({
        street: address.street,
        radius: mapDetails?.radius,
        locations: reverseGeocoding?.locations ?? [],
    });
};
