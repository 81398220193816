import type { Address, AddressNode, Street } from '@type/location/address';
import type { MapDetails } from '@type/location/coordinates';

/**
 * This config only includes address properties that are important from the business perspective, excluding country and postalCode that should not be displayed.
 * Those are ordered by preciseness - ordered, just to not be dependent on query response.
 */
const ADDRESS_PROPERTIES_ORDER: Omit<keyof Address, 'country' | 'postalCode'>[] = [
    'street',
    'subdistrict',
    'district',
    'city',
    'municipality',
    'county',
    'province',
];

export const formatAdvertAddress = (address: Address, mapDetails?: MapDetails): string => {
    const isExactLocation = mapDetails?.radius === 0;

    if (address.city && address.district && address.street) {
        // when all above properties were found - this is ideal output from the business perspective.

        if (isExactLocation) {
            return `${address.city.name}, ${address.district.name}, ${address.street.name} ${address.street.number}`;
        }

        return `${address.city.name}, ${address.district.name}`;
    }

    // Delete the street name when when location is not exact
    const addressOrder = isExactLocation ? ADDRESS_PROPERTIES_ORDER : ADDRESS_PROPERTIES_ORDER.slice(1);

    return addressOrder
        .map((key) => ({ [key as string]: address[key as keyof Address] }))
        .filter((value) => Object.values(value)[0] !== null) // we have to get rid of properties we couldn't resolve.
        .slice(0, 3) // from the business perspective, we need to limit number of properties rendered.
        .map((entry) => {
            const key = Object.keys(entry)[0] as keyof Address;
            const value = Object.values(entry)[0] as AddressNode;

            if (key === 'street') {
                const street = value as Street;

                return `${street.name} ${street.number}`;
            }

            return value.name;
        })
        .join(', ');
};
