import { TextButton } from '@domains/shared/components/TextButton/TextButton';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';
import Flag from '@nexus/lib-react/dist/core/Icon/icons/default/Flag';

export const ReportTextButton = styled(TextButton)`
    display: flex;
    margin: 24px auto;
`;

export const ReportAdNexusButton = styled(Button)`
    margin-top: -${theme.space['16']};
    color: ${theme.colors['text-global-error']};
`;

export const ReportAdNexusIcon = styled(Flag)`
    width: 24px;
    height: 24px;
    color: ${theme.colors['text-global-error']} !important;
`;
