import type { Image } from '@type/gallery/advertMedia';
import type { Address } from '@type/location/address';
import type { AdvertUserType } from '@type/user/userType';

export const checkIsDeveloperOwner = (owner: AdvertOwner): owner is DeveloperOwner => {
    return owner.__typename === 'DeveloperOwner';
};

export const checkIsAgencyOwner = (owner: AdvertOwner): owner is AgencyOwner => {
    return owner.__typename === 'AgencyOwner';
};

export type AdvertOwner = IndividualOwner | AgencyOwner | DeveloperOwner;

interface Owner {
    id: string;
    phones: string[];
    type: AdvertUserType;
}

export interface IndividualOwner extends Owner {
    name: FullName;
    __typename: 'IndividualOwner';
}

export interface AgencyOwner extends Owner {
    companyName: string;
    url: string | null;
    address: Address;
    image: Image | null;
    contacts: BusinessContact[];
    license: string;
    branding: AgencyBranding | null;
    awards: BusinessAwards[];
    slug: string;
    __typename: 'AgencyOwner';
}

export interface DeveloperOwner extends Owner {
    companyName: string;
    url: string | null;
    address: Address;
    image: Image | null;
    contacts: BusinessContact[];
    slug: string;
    __typename: 'DeveloperOwner';
}

interface FullName {
    firstName: string;
    secondName: string | null;
    lastName: string;
}

interface BusinessContact {
    id: string;
    name: FullName;
    phones: string[];
    image: Image;
}

interface AgencyBranding {
    url: string;
    isVisible: boolean | null;
}

interface BusinessAwards {
    name: string;
    value: string;
}
