import type { ContactModalVariant } from '@widgets/contactForm/types/contactModal';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useMemo, useState } from 'react';

export type AdPageModalVisibility = 'none' | ContactModalVariant;

export interface AdPageModalContextProps {
    modalVisibility: AdPageModalVisibility;
    toggleModalState: (newState: AdPageModalVisibility) => void;
    contactFormTrackingData: Record<string, unknown>;
    setContactFormTrackingData: (data: Record<string, unknown>) => void;
}

const toggleModalState: AdPageModalContextProps['toggleModalState'] = () => undefined;

export const AdPageModalContext = createContext<AdPageModalContextProps>({
    modalVisibility: 'none',
    toggleModalState,
    contactFormTrackingData: {},
    setContactFormTrackingData: () => undefined,
});

export const AdPageModalContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [previousState, setPreviousState] = useState<AdPageModalVisibility>('none');
    const [modalVisibility, setModalVisibility] = useState<AdPageModalVisibility>('none');
    const [contactFormTrackingData, setContactFormTrackingData] = useState<Record<string, unknown>>({});

    const value = useMemo(
        () => ({
            modalVisibility,
            contactFormTrackingData,
            setContactFormTrackingData,
            toggleModalState: (newState: AdPageModalVisibility): void => {
                const isContactModalVisible = [
                    'contactFromMissingInfo',
                    'default',
                    'suggestPriceContact',
                    'askAboutPriceContact',
                ].includes(modalVisibility);
                /* Should store the state of the modal before opening the agency contact modal */
                const shouldStorePreviousState = newState === 'agency' && isContactModalVisible;
                /* Should return to the correct contact modal (default/contactFromMissingInfo) when opening the agency one from within */
                const shouldReturnToPreviousState = previousState !== 'none';

                if (shouldStorePreviousState) {
                    setPreviousState(modalVisibility);
                } else {
                    setPreviousState('none');
                }

                if (shouldReturnToPreviousState) {
                    setModalVisibility(previousState);
                } else {
                    setModalVisibility(newState);
                }
            },
        }),
        [modalVisibility, previousState, contactFormTrackingData],
    );

    return <AdPageModalContext.Provider value={value}>{children}</AdPageModalContext.Provider>;
};
