import type { FileToUpload } from '@domains/shared/hooks/useFileUpload/types';
import type { JSX, PropsWithChildren } from 'react';
import { createContext, useState } from 'react';

interface ReportAdModalContextProps {
    currentStep: number;
    nextStep: () => void;
    previousStep: () => void;
    reset: () => void;
    fileToUpload: FileToUpload | null;
    setFileToUpload: (fileToUpload: FileToUpload | null) => void;
}

export const ReportAdModalContext = createContext<ReportAdModalContextProps>({
    currentStep: 0,
    nextStep: () => undefined,
    previousStep: () => undefined,
    reset: () => undefined,
    fileToUpload: null,
    setFileToUpload: () => undefined,
});

export const ReportAdModalContextProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [fileToUpload, setFileToUpload] = useState<FileToUpload | null>(null);
    const nextStep = (): void => {
        setCurrentStep(currentStep + 1);
    };

    const previousStep = (): void => {
        setCurrentStep(currentStep - 1);
    };

    const reset = (): void => {
        setCurrentStep(1);
        setFileToUpload(null);
    };

    return (
        <ReportAdModalContext.Provider
            value={{
                currentStep,
                nextStep,
                previousStep,
                reset,
                fileToUpload,
                setFileToUpload,
            }}
        >
            {children}
        </ReportAdModalContext.Provider>
    );
};
