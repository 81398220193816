import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import StarFilled from '@nexus/lib-react/dist/core/Icon/icons/default/StarFilled';

const LEADER_BADGE_COLOR = theme.colors['background-brand-badge'];

export const LeaderBadgeIcon = styled(StarFilled)`
    width: ${theme.space[16]};
    height: ${theme.space[16]};
    padding: 1px;
    border: ${theme.borderWidths[2]} solid ${LEADER_BADGE_COLOR};
    border-radius: 50%;
    background-color: ${theme.colors.$01_white};
    color: ${LEADER_BADGE_COLOR};
`;

export const LeaderProBadgeIcon = styled(LeaderBadgeIcon)`
    padding: ${theme.space[2]};
    border: none;
    background-color: ${LEADER_BADGE_COLOR};
    color: ${theme.colors.$01_white};
`;

export const LeaderBadgeWrapper = styled.div`
    display: inline;
    height: ${theme.space[16]};

    & > div {
        display: inline;
        vertical-align: middle;
    }
`;
