import { normalizeDeprecatedTranslationKey } from '@domains/shared/helpers/normalizeDeprecatedTranslationKey';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { RoomsValue } from '@type/ad/adDetails';

export type ItemValue = string | number | boolean | undefined | null | Array<string>;

export const DESCRIPTION_LINK_REGEXP = /\[([^[\]]*?)]\(([^(]*?)\)/;

export const getDescriptionForRooms = ({
    t,
    translationPrefix,
    values,
}: {
    t: Translator;
    translationPrefix: string;
    values: RoomsValue[];
}): string => {
    return values
        .map((item) => {
            const roomNameTranslationKey = t(`${translationPrefix}value-${item.value.split('::').pop()}`);

            return `${roomNameTranslationKey} - ${item.values[0]}${item.unit}`;
        })
        .join(', ');
};

export const getDescriptionForPreviewPage = ({
    label,
    t,
    translationPrefix,
    value,
}: {
    label: string;
    t: Translator;
    translationPrefix: string;
    value: ItemValue;
}): string => {
    if (label === 'build-year') {
        return value ? String(value) : '';
    }

    if (['floor', 'rent', 'area', 'rooms_num', 'energy_certificate'].includes(label) && typeof value === 'string') {
        return value;
    }

    if (Array.isArray(value) && label === 'rooms') {
        return value.join(', ');
    }

    if (typeof value === 'string') {
        return t(`${translationPrefix}value-${normalizeDeprecatedTranslationKey(value)}`);
    }

    if (typeof value === 'boolean') {
        return t(`${translationPrefix}value-${value === true ? 'yes' : 'no'}`);
    }

    if (Array.isArray(value) && value.every((value) => typeof value === 'string')) {
        return value
            .map((value: string) => t(`${translationPrefix}value-${normalizeDeprecatedTranslationKey(value)}`))
            .join(', ');
    }

    return '';
};
