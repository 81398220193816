import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { Tooltip } from '@nexus/lib-react/dist/core/Tooltip';
import type { SellerBadge, SellerBadgeId } from '@type/sellerBadge/sellerBadge';
import { SELLER_BADGE_ID } from '@type/sellerBadge/sellerBadge';
import { useContext } from 'react';

import { LeaderBadgeIcon, LeaderBadgeWrapper, LeaderProBadgeIcon } from './LeaderBadgeIcon.theme';

interface Props {
    sellerBadges?: SellerBadge[];
    className?: string;
}

const LEADER_BADGES_IDS = new Set<SellerBadgeId>([SELLER_BADGE_ID.leader, SELLER_BADGE_ID.leaderPro]);

export const LeaderBadge = ({ sellerBadges = [], className }: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);
    const { featureFlags } = useSiteSettings();
    const tooltipTriggerEvent = isDesktop ? 'mouseenter' : 'click';

    const { isBadgesFeatureEnabled } = featureFlags;
    const leaderBadgeId = sellerBadges.find((badge) => LEADER_BADGES_IDS.has(badge.id))?.id;

    const isLeaderPro = leaderBadgeId === SELLER_BADGE_ID.leaderPro;

    if (!leaderBadgeId || !isBadgesFeatureEnabled) {
        return null;
    }

    return (
        <LeaderBadgeWrapper className={className}>
            <Tooltip
                content={
                    isLeaderPro
                        ? t('frontend.global.seller-badge.leader-pro-tooltip')
                        : t('frontend.global.seller-badge.leader-tooltip')
                }
                trigger={tooltipTriggerEvent}
                verticalOffset={14}
                placement="top"
            >
                {isLeaderPro ? (
                    <LeaderProBadgeIcon aria-label={t('frontend.global.seller-badge.leader-pro')} />
                ) : (
                    <LeaderBadgeIcon aria-label={t('frontend.global.seller-badge.leader')} />
                )}
            </Tooltip>
        </LeaderBadgeWrapper>
    );
};
