import type { Ad } from '@domains/ad/types/Ad';
import type { ContactFormAdvertData } from '@type/ad/contactForm';

export const getContactFormAdvertProps = (advert: Ad): ContactFormAdvertData => {
    return {
        advertCategoryId: advert.category.id,
        advertId: advert.id,
        advertSource: advert.source,
        openDay: advert.openDay,
        topInformation: advert.topInformation,
        cityName: advert.location.address.city?.name,
        links: advert.links,
    };
};
