import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin: ${theme.space[24]} 0;
    padding: ${theme.space[16]};
    border-width: ${theme.borderWidths[1]};
    border-style: ${theme.borderStyles.solid};
    border-radius: ${theme.radii[4]};
    border-color: ${({ theme }): CSSProperties['borderColor'] =>
        theme.deprecated.domains.ad.expiredAdAlert.borderColor};
    background-color: ${({ theme }): CSSProperties['backgroundColor'] =>
        theme.deprecated.domains.ad.expiredAdAlert.backgroundColor};
    gap: ${theme.space[16]};

    @media ${theme.mediaQueries.lgMin} {
        margin: ${theme.space[32]} 0;
    }
`;

export const AlertText = styled.p`
    margin: 0;
    color: ${({ theme }): CSSProperties['color'] => theme.deprecated.domains.ad.expiredAdAlert.color};
    font-size: ${theme.typography.p3.fontSize};
`;

export const BoldText = styled.span`
    display: block;
    font-size: ${theme.typography.p2.fontSize};
    font-weight: ${theme.fontWeights[600]};
    line-height: 150%;
`;

export const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
    line-height: 24px;
`;
