import { AdHeadMetadata } from '@domains/ad/components/AdHeadMetadata/AdHeadMetadata';
import { ExpiredAdAlert } from '@domains/ad/components/ExpiredAdAlert/ExpiredAdAlert';
import { ExpiredAdContent } from '@domains/ad/components/ExpiredAdContent/ExpiredAdContent';
import { RecommendedAdsSectionSkeleton } from '@domains/ad/components/RecommendedAdsSection/RecommendedAdsSection.skeleton.theme';
import { SeeSimilarAdsLinkButton } from '@domains/ad/components/SeeSimilarAdsLinkButton/SeeSimilarAdsLinkButton';
import { useRecommendedAdvertsFromSearchResults } from '@domains/ad/hooks/useRecommendedAdvertsFromSearchResults';
import { ExpiredAdContentLayout } from '@domains/ad/layouts/ExpiredAdContentLayout/ExpiredAdContentLayout.theme';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { MainLayout } from '@lib/layouts/MainLayout/MainLayout';
import { ContentBackground } from '@lib/pages/contentBackground';
import dynamic from 'next/dynamic';
import type { FC } from 'react';

import type { AdPageProps } from './AdPage';

const LazyRecommendedAdsSection = dynamic(
    () => import('@domains/ad/components/RecommendedAdsSection/RecommendedAdsSection'),
    { loading: () => <RecommendedAdsSectionSkeleton /> },
);

export const ExpiredAdPage: FC<AdPageProps> = ({ ad, relativeUrl = '' }) => {
    const { similarAdsLinkUrl } = ad;
    const { lang } = useSiteSettings();
    const { recommendedAdverts } = useRecommendedAdvertsFromSearchResults(ad);
    const hasRecommendations = recommendedAdverts.length > 0;

    return (
        <>
            <AdHeadMetadata ad={ad} relativeUrl={relativeUrl} lang={lang} />
            <MainLayout hasStickyPageHeader={false} contentBackground={ContentBackground.none} isMinimal>
                <ExpiredAdContentLayout>
                    <ExpiredAdAlert />
                    <ExpiredAdContent ad={ad} />
                    {hasRecommendations ? (
                        <LazyRecommendedAdsSection
                            adverts={recommendedAdverts}
                            hasBorderlessTitle={true}
                            title="frontend.expired-ad.similar-ads-section.headline"
                        />
                    ) : null}
                    {similarAdsLinkUrl ? <SeeSimilarAdsLinkButton linkUrl={similarAdsLinkUrl} /> : null}
                </ExpiredAdContentLayout>
            </MainLayout>
        </>
    );
};
