import {
    ASIDE_CONTAINER_DESKTOP_WIDTH,
    FULL_WIDTH_MAIN_CONTAINER_DESKTOP_WIDTH,
    MAIN_CONTAINER_DESKTOP_WIDTH,
} from '@domains/ad/consts/adPageContentWidths';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${theme.space['16']};
    justify-content: center;

    @media ${theme.mediaQueries.lgMin} {
        grid-template-columns: 65% 35%;
        margin: 0 ${theme.space['24']};
        padding-top: ${theme.space['16']};
    }

    @media ${theme.mediaQueries.xlMin} {
        grid-template-columns: ${MAIN_CONTAINER_DESKTOP_WIDTH} ${ASIDE_CONTAINER_DESKTOP_WIDTH};
    }
`;

export const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space['16']};

    @media ${theme.mediaQueries.lgMin} {
        gap: ${theme.space['24']};
    }
`;

export const AsideContent = styled.div`
    display: none;

    @media ${theme.mediaQueries.lgMin} {
        display: block;
    }
`;

export const FullWidthMainContentSection = styled.div<{ addTopSpacing?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: ${theme.space['0']} auto;
    margin-top: ${({ addTopSpacing }): CSSProperties['marginTop'] => (addTopSpacing ? theme.space['40'] : 0)};
    gap: ${theme.space['40']};

    @media ${theme.mediaQueries.xlMin} {
        width: ${FULL_WIDTH_MAIN_CONTAINER_DESKTOP_WIDTH};
    }
`;
